form.trigger-form {
    margin-top: 1em;
}

.form-switch .col-sm-1 {
    width: 2%;
}

.form-switch .col-sm-2 {
    width: 4%;
}

.form-switch .col-sm-6 {
    width: 43.6666%;
}

.form-check-input {
    margin-right: 0.5em;
}

.form-switch {
    margin-bottom: 0.125rem;
}

#source-code {
    display: inline-block;
    float: right;
}

#trigger-build {
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 0.5;
}

a {
  text-decoration: none;
}
